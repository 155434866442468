/** @jsx jsx */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

import HeroImage from '../components/shared/HeroImage';
import Link from '../components/shared/Link';
import ProductListing from '../components/ProductListing';
import { H1 } from '../components/shared/Typography';

const MdxPageTemplate = ({ data, location }) => {
  const { title, description } = data.mdx.frontmatter.meta;
  const { hero } = data.mdx.frontmatter;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={`https://www.lockcha.com${location.pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      {hero && (
        <HeroImage
          alt={data.mdx.frontmatter.hero.imageAlt}
          imagePath={data.mdx.frontmatter.hero.image}
          objectPosition={`50% ${data.mdx.frontmatter.hero.imagePositionY || '50%'}`}
        />
      )}
      <MDXProvider
        components={{
          a: Link,
          // eslint-disable-next-line react/display-name, react/prop-types
          h1: ({ children }) => <H1 sx={{ textAlign: 'center' }}>{children}</H1>,
          // eslint-disable-next-line react/display-name, react/prop-types
          wrapper: ({ children }) => (
            <article sx={{ mb: 3, mx: 'auto', px: [2, 3], maxWidth: '96rem' }}>{children}</article>
          ),
        }}
      >
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </MDXProvider>
      {data.products.nodes.length > 0 && (
        <ProductListing
          sx={{ mt: 4, mx: 'auto', width: '100%', maxWidth: '96rem' }}
          products={data.products}
          shopifyProducts={data.shopifyProducts}
        />
      )}
    </React.Fragment>
  );
};

export const query = graphql`
  query MdxPageTemplatepageQuery($mdxHandle: String, $categoryHandle: String, $locale: String) {
    mdx(frontmatter: { handle: { eq: $mdxHandle }, locale: { eq: $locale } }) {
      frontmatter {
        meta {
          title
          description
        }
        hero {
          image
          imageAlt
          imagePositionY
        }
      }
      body
    }
    ...ProductListing
  }
`;

MdxPageTemplate.displayName = 'MdxPageTemplate';

MdxPageTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default MdxPageTemplate;
