/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';
import { jsx } from 'theme-ui';

// eslint-disable-next-line react/prop-types, react/display-name
const HeroImage = ({ siteImages, imagePath, fallback, ...imageProps }) => {
  // eslint-disable-next-line react/prop-types
  const image = siteImages.find(({ relativePath }) => relativePath === imagePath);

  if (image && image.childImageSharp) {
    imageProps.image = image.childImageSharp.gatsbyImageData;
  } else {
    imageProps.src = fallback;
  }

  return <GatsbyImage sx={{ maxHeight: '45vh' }} {...imageProps} />;
};

// eslint-disable-next-line react/prop-types, react/display-name
export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { ext: { eq: ".jpg" }, relativeDirectory: { eq: "hero" } }) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    `}
    render={({ allFile: { nodes } }) => {
      return <HeroImage siteImages={nodes} {...props} />;
    }}
  />
);
